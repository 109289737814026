import { useRouter } from 'next/router'
import { Context, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { createUAParser, getDeviceType } from '../../hooks/useDeviceInfo'
import { ItemStorage } from '../../newPackages/StorageProviders/localStorageProvider'
import { Logger } from '../../utils/logger'
import { ApiPlatform, Config } from './Config'

const logger = Logger.of('ConfigService')

export type ConfigService = {
  appVersion: string
  nativeAppVersion: string
  apiEnvironment: string
  currentConfig: Config
}

export const ConfigServiceContext = createContext<ConfigService | undefined>(undefined)

type DefinedContext<T> = Exclude<T, undefined>
export function useContextUnconditionally<T>(context: Context<T>): DefinedContext<T> {
  const contextValue = useContext(context)
  if (contextValue === undefined) {
    logger.error('Context was used before data was fetched.  This should not be possible.  Check render method')
  }
  return contextValue as DefinedContext<T>
}

const configStorage = new ItemStorage<Config | undefined>('config', undefined)

// https://server.com/configapi/<LASTKNOWN CHECKSUM>/X-APIversion/X-brand/X-appPlatform/X-appModel/X-appVersion/X-appOSversion/X-appLanguage
type ConfigURLProps = {
  checksum: string
  appVersion: string
  apiEnvironment: string // 0,6,8
  apiPlatform: ApiPlatform
}

type ConfigServiceProviderProps = {
  appVersion: string
  nativeAppVersion: string

  /** The ConfigAPI environment to use */
  apiEnvironment: string
  apiPlatform: ApiPlatform
}

const getBrowserVersion = (): string => {
  const ua = createUAParser()
  // get browser version
  const browser = ua.getBrowser()
  return browser?.version ?? '1.0.0'
}

const buildConfigURL = ({ checksum, appVersion, apiEnvironment, apiPlatform }: ConfigURLProps) => {
  const url = new URL('https://configapi.ballysports.com')
  url.pathname = [
    checksum,
    '1.0.6', // Config API Version
    'ballysports', // Brand
    apiPlatform, // Platform
    getDeviceType(), // Model
    `${appVersion}.${apiEnvironment}`, // App Version
    getBrowserVersion(), // Browser/OS Version
    'en-US', // Language
  ].join('/')

  return url.toString()
}

const pollInterval = 1000 * 60 * 60 * 12 // 12hrs

export const ConfigServiceProvider = ({
  children,
  nativeAppVersion,
  appVersion,
  apiEnvironment,
  apiPlatform,
}: React.PropsWithChildren<ConfigServiceProviderProps>) => {
  const router = useRouter()
  const [config, setConfig] = useState<ConfigService | undefined>(undefined)
  const checksum = useRef()
  const checksumValid = useRef(true)

  const fetchConfig = useCallback(async () => {
    const checksum = 'default'
    const response = await fetch(buildConfigURL({ checksum, appVersion, apiEnvironment, apiPlatform }))
    return await response.json()
  }, [apiEnvironment, apiPlatform, appVersion])

  useEffect(() => {
    const setInitialConfig = async () => {
      const _config = await fetchConfig()
      checksum.current = _config.checksum
      configStorage.setItem(_config)
      setConfig({
        appVersion,
        nativeAppVersion,
        apiEnvironment,
        currentConfig: { ..._config, apiPlatform },
      })
      logger.debug('Config loaded', { config: _config })
    }
    setInitialConfig()
    // These dependency array values all come from clientRuntimeConfig and should never change
  }, [nativeAppVersion, appVersion, apiEnvironment, apiPlatform, fetchConfig])

  // This effect runs once.
  // It sets up a poll to check for config changes.
  useEffect(() => {
    const validateChecksum = async () => {
      const _config = await fetchConfig()
      // If we have a new config, set checksumValid to false
      // This will not trigger a render, but allow us to take action the next time we do
      if (checksum.current !== _config.checksum) {
        logger.debug('Checksum invalid.  Force reloading on next render.', {
          stale_checksum: checksum.current,
          current_checksum: _config.checksum,
        })
        checksumValid.current = false
        // No need to continue polling since we know we are reloading
        clearInterval(intervalId)
      }
    }
    const intervalId = setInterval(validateChecksum, pollInterval)
    return () => clearInterval(intervalId)
  }, [fetchConfig])

  // This effect will run on every render.
  // Since ConfigService is rendered by _app.tsx, this is equivalent to a page load.
  // If the checksum is invalid, the router will reload, fetching new configs and app code.
  useEffect(() => {
    // checksumValid defaults to true on initialization so will not cause unwanted reloads.
    if (!checksumValid.current) {
      logger.debug('Force reloading now.')
      router.reload()
    }
  })

  if (config) {
    return <ConfigServiceContext.Provider value={config}>{children}</ConfigServiceContext.Provider>
  } else {
    return <></>
  }
}
